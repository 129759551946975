.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #2a292c;
}

.product-modal {
  margin-top: 350px;
  position: absolute;
  background-color: rgb(226, 223, 223);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}


.products-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  background-size: 100% auto;

  /* Defina uma altura máxima em relação à altura da tela */
  max-height: calc(100vh - 70px);
  /* Você pode ajustar o valor conforme necessário */
  overflow-y: auto;
}

.text-center {
  text-align: center;
}

.title {
  font-size: 36px;
  color: white;
  margin-bottom: 30px;
}

.subtitle {
  font-size: 18px;
  color: white;
  margin-bottom: 50px;
}

.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}

.product-card img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}


.product-name {
  font-size: 20px;
  font-weight: bold;
  color: #555;
}

.product-description {
  margin-top: 2px;
  font-size: 16px;
  color: #555;
}

.details-container {
  display: flex;
  justify-content: space-between;
  /* Espaçamento entre as colunas */
  align-items: center;
  /* Alinhar verticalmente ao centro */
  width: 900px;
  height: 700px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
}

.left-column,
.right-column {
  flex: 1;
  padding: 10px;
  height: 600px;
  margin-left: 20px;
  margin-right: 10px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.data-section,
.payment-section,
.download-section,
.payment-section,
.support-section,
.reset-password-section {
  margin-bottom: 20px;
}

.data-section h3,
.payment-section h3,
.download-section h3,
.payment-section h3,
.support-section h3,
.reset-password-section h3,
.product-section h3,
.expiration-section h3,
.website-section h3,
.tutorial-section h3 {
  font-size: 18px;
  color: #333;
}


.products-container.fade-out,
.title.fade-out,
.subtitle.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.details-container.fade-in {
  opacity: 1;
  transition: opacity 1.5s ease;
}

/* Adicione estilos para as informações do produto selecionado aqui */


button {
  background-color: #007bff;
  margin-left: 5px;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}


input[type="password"] {
  width: 80%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 14px;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Modal styles */
.reset-password-modal {
  width: 80%;
  max-width: 400px;
  /* Limitar a largura máxima */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: none;
}

@media (max-width: 900px) {
  .details-container {
    width: 90%;
    max-width: 100%;
    height: 90%;
    padding: 10px;
  }

  .left-column,
  .right-column {
    width: 90%;
    height: 90%;
  }
}

/* Estilos para telas menores (até 600px de largura) */
@media (max-width: 600px) {
  .container {
    background-size: 100% auto;
  }

  .title,
  .subtitle {
    margin: 10px 0;
    text-align: center;
  }

  .products-container {
    width: 90%;
    /* Ocupar toda a largura disponível */
    max-height: calc(100vh - 70px);
    /* Mesmo cálculo da altura máxima */
    grid-template-columns: 1fr;
    gap: 10px;
    overflow-y: auto;
  }

  .product-card img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .product-card {
    padding: 10px;
  }

  .details-container {
    display: inline;
    width: 90%;
    max-width: 100%;
    height: 100%;
    padding: 10px;
  }

  .left-column,
  .right-column {
    width: 85%;
    margin-top: 25px;
    height: auto;
  }

  input[type="text"] {
    width: 80%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: 5px;
    /* Reduzir a margem inferior */
  }

  button {
    margin-left: 5px;
  }

  button.reset-password-button {
    margin-left: 5px;
  }

  .reset-password-modal {
    margin-top: 700px;
    position: absolute;
    background-color: rgb(226, 223, 223);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    display: none;
  }


  .show-reset-modal {
    display: block;
  }
}

/* Estilos para telas maiores (a partir de 1200px de largura) */
/* @media (min-width: 1200px) {
  .details-container {
    width: 1100px;
    margin: 0 auto;
  }
} */